import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlogContent from "./blog-content";
const BlogDetails = () => {
  return (
    <section className="blog-details pt-40 pb-40 patch-bg" id="blog-cards">
      <Container>
        <Row className="justify-content-center blog-content-padding" >
          <Col md={12} lg={12}>
            <BlogContent />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BlogDetails;
