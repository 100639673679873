
import React, { useState, useEffect } from "react";
import api from "../../utility/apis"
import { Link } from 'gatsby';
import { Container, Col, Row } from "react-bootstrap";
import backArrow from '../../../static/assets/images/arrows/back.svg'
const BlogContent = () => {
  const [blogContent, setAllBlogContent] = useState([]);
  const getAllBlogs = async () => {
    let { data } = await api
      .get("/trd-portal-blog-articles?populate=*&sort=id:asc", 3000)
      .catch((err) => console.log("something went wrong!!!"));
    setAllBlogContent(data);
  };
  useEffect(() => {
    getAllBlogs().catch((err) => console.log("something went wrong!!"));
  }, [blogContent]);
  return (
    <>
      <section id="blog-description">
        <Container fluid>
          <Row noGutters={true} className="justify-content-center text-center">
            <Col lg={12} md={12} sm={12} sx={12} className="text-end">
              <ul className="blog-back-arrow-div">
                <Link to='/Blog'>
                  <li className="blog-back-arrow">
                    <img src={backArrow} className="mr-2 mt-1 blog-arrow-img" />
                    {" "}
                    <span>Back</span>
                  </li>
                </Link>
              </ul>
            </Col>
          </Row>
        </Container>
        <div>
          {(blogContent.data && blogContent.data.length > 0) && blogContent.data.map((item) => {
            console.log(item)
            let locationVariable = window.location.href.split("?");
            let dateArray = new Date(item.attributes.publishedAt);
            const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
            const Cards = locationVariable[1] == item.id ? (
              <div class="blog-right">
                <div id="img-blog-desc">
                  <img src={item.attributes.image.data.attributes.formats.thumbnail != undefined ? item.attributes.image.data.attributes.formats.thumbnail.url : item.image.data.attributes.url} alt="" className="blog-desc-image img-blog-desc" />
                </div>
                <h3>{item.attributes.title}</h3>
                <div id="date-font">{dateArray.toLocaleDateString("en-in", options)}</div>
                <div className="blog-card__meta d-flex justify-content-start mt-0 mb-0">
                  {
                    item.attributes.trd_portal_blog_writer.data && (
                      <a href="news-details.html">
                        <i className="far fa-user-circle"></i> {item.attributes.trd_portal_blog_writer.data.attributes.name}
                      </a>
                    )
                  }
                </div>
                <p className="img-blog-p">
                  {item.attributes.description}
                </p>
              </div>
            ) : (
              <>{console.log(item, "item value")}</>
            );
            return Cards;
          })}
        </div>
      </section>
    </>
  )
};
export default BlogContent;